import React, { Component } from 'react'
import ShadowBox from '../../components/ShadowBox/ShadowBox';
import Icon from "../../components/Icon"
import "./Contact.css"

export default class Contact extends Component {
    render() {
        return (
            <ShadowBox>
                <div className="text-wrapper">
                    <h3>Contact Information</h3>
                        <p>
                            patrick1dasilva@gmail.com
                        </p>
                    <Icon/>
                </div>
            </ShadowBox>
        )
    }
}
