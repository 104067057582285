import React, { Component } from "react";
import { Navbar, Nav, NavbarBrand } from "react-bootstrap";

export default class Footer extends Component {
    render() {
        return (
            <Navbar fixedBottom inverse fluid collapseOnSelect>
                <Nav pullRight>
                    <NavbarBrand>
                        Created by Patrick Da Silva and Kerstin Simone Maier
                    </NavbarBrand>
                </Nav>
            </Navbar>
        );
    }
}