import React from "react";
import "./ShadowBox.css"

const shadowBox = props => {
    return (
        <div className="ShadowBox">
            {props.children}
        </div>
    );
};

export default shadowBox;
