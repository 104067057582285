import React, { Component } from 'react'
import Card from "../../components/Cards/Card"

export default class Project extends Component {
    render() {
        return (
            <Card/>
        )
    }
}
