import React from "react";
import ShadowBox from "../../components/ShadowBox/ShadowBox"
import "./Game2048.css";

export default () => (
    <ShadowBox>
        <div className="content-wrapper">
            <h3>Under construction!</h3>
            <p>
                The idea of this project will be to solve the 2048 game using AI, more precisely reinforcement learning. Currently, this is working in progress. Stay posted for updates on the homepage!
            </p>
            <p>This project is not ready yet. An update will be on the home page when it is done!</p>

        </div>
    </ShadowBox>
);