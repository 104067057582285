import React from "react";
import "./App.css";
import {Helmet} from "react-helmet"
import Header from "./header/Header";
import Footer from "./footer/Footer";
import Routes from "./Routes";

class App extends React.Component {
    render() {
        return (<>
            <Helmet>
                <title>Patrick Da Silva</title>
                <meta 
                    name="Patrick Da Silva - Portfolio" 
                    content="Patrick Da Silva"
                />
            </Helmet>
            <Header />
            <div className="App">
                <Routes />
            </div>
            <Footer />
        </>);
    }
}

export default App;
