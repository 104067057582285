import React, { Component } from "react";
import ShadowBox from '../../components/ShadowBox/ShadowBox'
import "./Home.css"

export default class Home extends Component {
    render() {
        return (
            <div style={{display: "flex", flexDirection: "column"}}>
                <ShadowBox>
                    <div className="headline-wrapper">
                        <h1>Patrick Da Silva</h1>
                        <h2>Your machine learning expert for all your AI needs</h2>
                    </div>
                </ShadowBox>
                <ShadowBox>
                    <div className="content-wrapper">
                        <h3>Update</h3>
                        <hr></hr>
                        <p>21.08.19</p>
                        <p>
                            I have made some progress on the chatbot! Before, I had integrated a chatbot in websites written in HTML/CSS/JavaScript, so I could use a chat window that integrated well with such pages. Now that this website is written entirely with React, I have to tweak a few things before the chat can be connected with a chatbot. We are almost there! You can have a look at the chat window itself (called a chatroom) in the Projects tab. Feel free to send me some feedback!
                        </p> 
                    </div>
                </ShadowBox>
                <ShadowBox>
                    <div className="content-wrapper">
                        <h3>News</h3>
                        <hr></hr>
                        <p>01.08.19</p>
                        <p>
                            I am happy to announce that my portfolio website is ready to launch! Over the next few days, a lot of content will be added. Check out the projects tab to see what I have planned ahead! About the website itself, it is currently only well-rendered on desktops; mobile coming soon, but responsiveness is... not easy to achieve!
                        </p> 
                        <p>
                            I would like to thank Kerstin Simone Maier for supporting me in the project and helping me with the front-end, and Daniel Montano for answering my numerous questions!
                        </p>
                        <p>
                            P.S. : I am currently on the job hunt, so check out my CV in the CV tab!
                        </p>
                    </div>
                </ShadowBox>
            </div>
        );
    }
}