import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default class Header extends Component {
    render() {
        return (
            <Navbar fixedTop inverse fluid collapseOnSelect>
                <Navbar.Header>
                    <Navbar.Brand>
                        <Link to="/">Patrick Da Silva</Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav pullRight>
                        <LinkContainer to="/projects">
                            <NavItem>
                                Projects
                                </NavItem>
                        </LinkContainer>
                        <LinkContainer to="/cv">
                            <NavItem>
                                CV
                                </NavItem>
                        </LinkContainer>
                        <LinkContainer to="/contact">
                            <NavItem>
                                Contact
                                </NavItem>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}
