import React, { Component } from 'react';
import { Widget, addResponseMessage, dropMessages } from 'react-chat-widget';

import './ChatWidget.css';

class ChatRoom extends Component {
    componentDidMount() {
        dropMessages();
        addResponseMessage("Hi! I'm Patrick's personal assistant.");
        // addResponseMessage("I am now currently under construction, but you will soon be able to ask me questions about him.");
        addResponseMessage("Feel free to try me out for now!");
    }

    state = {
        messageWritten: false
    }

    handleNewUserMessage = (newMessage) => {
        if (!this.state.messageWritten) {
            addResponseMessage("I'm sorry. Like I told you, I don't understand messages yet. I am under construction!")
            this.setState({
                messageWritten: true
            })
        }
        // console.log(`New message incoming! ${newMessage}`);
        // Now send the message throught the backend API
    }

    render() {
        return (
            <Widget 
                handleNewUserMessage={this.handleNewUserMessage}
                subtitle=""
                title="Patrick's personal assistant"
            />
        );
    }
}

export default ChatRoom;