import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home/Home";
import Projects from "./containers/Projects/Projects";
import CV from "./containers/CV/CV";
import Contact from "./containers/Contact/Contact";
import page404 from "./containers/Page404/page404"
import Chatbot from "./containers/Chatbot/Chatbot"
import Game2048 from "./containers/Game2048/Game2048"
import Mathematics from "./containers/Mathematics/Mathematics"

export default () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/projects" exact component={Projects} />
    <Route path="/projects/chatbot" exact component={Chatbot} />
    <Route path="/projects/game2048" exact component={Game2048} />
    <Route path="/projects/mathematics" exact component={Mathematics} />
    <Route path="/cv" exact component={CV} />
    <Route path="/contact" exact component={Contact} />
    <Route component={page404} />
  </Switch>
);