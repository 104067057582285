import React from "react";
import { Link } from "react-router-dom";
import "./Card.scss"

export default class Card extends React.Component {
    render() {
        return (
            <div className="card-container">
                <div className="card">
                    <input type="checkbox" id="card1" className="more" aria-hidden="true" />
                    <div className="content">
                        <div className="front chatbot">
                            <div className="inner">
                                <h2>Chatbot</h2>
                                <label htmlFor="card1" className="button" aria-hidden="true">
                                    Details
                                </label>
                            </div>
                        </div>
                        <div className="back">
                            <div className="inner">
                                <div className="title">Personal chatbot</div>
                                <div className="description">
                                    <p>Talk to my chatbot to get to know me! He can tell you all about my professional experience.</p>
                                </div>
                                <Link className="button return" to="./projects/chatbot">
                                    See more</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <input type="checkbox" id="card2" className="more" />
                    <div className="content">
                        <div className="front game2048">
                            <div className="inner">
                                <h2>2048</h2>
                                <label htmlFor="card2" className="button" aria-hidden="true">
                                    Details
                                </label>
                            </div>
                        </div>
                        <div className="back">
                            <div className="inner">
                                <div className="title">Reinforcement Learning - 2048</div>
                                <div className="description">
                                    <p>Interested in reinforcement learning and unable to solve this game myself, I decided to see what the latest machine learning techniques are made of. Check it out!</p>
                                </div>
                                <Link className="button return" to="./projects/game2048">
                                    See more</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <input type="checkbox" id="card3" className="more" aria-hidden="true" />
                    <div className="content">
                        <div className="front flat-torus">
                            <div className="inner">
                                <h2>Mathematics</h2>
                                <label htmlFor="card3" className="button" aria-hidden="true">
                                    Details
                                </label>
                            </div>
                        </div>
                        <div className="back">
                            <div className="inner">
                                <div className="title">Mathematics</div>
                                <div className="description">
                                    <p>In this project, I am displaying mathematical projects that I work on during my free time. Feel free to contact me and discuss that content!</p>
                                </div>
                                <Link className="button return" to="./projects/mathematics">
                                    See more</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}