import React, { Component } from 'react'
import ShadowBox from "../../components/ShadowBox/ShadowBox"
import { Link } from "react-router-dom";
import "./CV.css"

import ProfilePic from "../../assets/images/profile-pic.png"

export default class CV extends Component {
    render() {
        const CVUrl = "https://www.dropbox.com/s/0oz1e09zmthljk8/Patrick%20Da%20Silva%20-%20CV.pdf?dl=0"
        
        return (
                <div style={{display:"flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection:"row"}}>
                        <ShadowBox>
                            <div className="content-wrapper">
                                <h3><a 
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        href={CVUrl}
                                        >Curriculum Vitae</a></h3>
                                <p>
                                    I am originally from Montreal, where I grew up and did my Bachelor's degree in mathematics. In a sort of surprising manner, my life completely turned around at the end of my Bachelor's where I decided to move to Berlin for my graduate studies. I fell in love with this city and have been living here ever since. I began a PhD in mathematics, but seeing that the financial and professional life of a mathematician isn't exactly fantastic for the first years after the PhD (regardless of how good you are), I made the decision to move to the industry and make a career in the fields of data science and machine learning.
                                </p>
                                <p>
                                    I used to play handball and football recreationally. I now live in Berlin with my girlfriend and a beautiful baby dog (she's a 1 year old little Beagle, and she's super cute!). It's important to me that I can bring her to work 2 to 3 times a week so that she doesn't stay alone all the time.
                                </p>
                                <p>
                                    You can find my CV 
                                    <a 
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        href={CVUrl}
                                        > here
                                    </a>. Feel free to get in touch with me via my <Link to="/contact">contact information</Link>.
                                </p>
                            </div>
                        </ShadowBox>
                        <img src={ProfilePic} alt="" className="profile-pic"></img>
                    </div>
                    <div style={{display: "flex", flexDirection:"row"}}>
                        <ShadowBox>
                            <div className="content-wrapper">
                                <h3>Work Experience</h3>
                                <p>I just left a job at the start-up incubator HellaFastForward. Attempting to build a chatbot company for a year where I would have been the CTO, I learned to use a plethora of tools in the developer world: gaining experience with Python and the usual Data Science & Machine Learning stack :</p>
                                <div style={{display: "flex", flexContainer: "row", justifyContent: "space-evenly"}}>
                                    <p>
                                        Data Science
                                        <p>
                                            <ul>
                                                <li> pandas</li>
                                                <li> numPy</li>
                                                <li> scikit-learn</li>
                                                <li> matplotlib</li>
                                            </ul>
                                        </p>
                                    </p>
                                    <p>
                                        Machine Learning
                                        <p>
                                            <ul>
                                                <li> TensorFlow</li>
                                                <li> Keras</li>
                                                <li> <a href="https://spacy.io">spaCy</a> (NLP)</li>
                                                <li> <a href="https://rasa.com/">Rasa</a></li>
                                            </ul>
                                        </p>
                                    </p>
                                    <p>
                                        DevOps
                                        <p>
                                            <ul>
                                                <li> Flask</li>
                                                <li> Docker</li>
                                                <li> AWS</li>
                                            </ul>
                                        </p>
                                    </p>
                                    <p>
                                        Databases
                                        <p>
                                            <ul>
                                                <li> MySQL</li>
                                                <li> MongoDB</li>
                                            </ul>
                                        </p>
                                    </p>
                                </div>
                                <p>
                                    Unfortunately, many factors led me to not pursue the project to its foundation, and I am now looking for more experience in those areas. I believe that I already have loads of experience in those fields but that my talent needs to be refined and specialized to become extremely valuable.
                                </p>
                            </div>
                        </ShadowBox>
                        <div style={{margin: "150px 150px 150px 100px", paddingLeft: "229px"}}></div> 
                        {/* GHOST IMAGE */}
                    </div>    
                    <div style={{display: "flex", flexDirection:"row"}}>
                        <ShadowBox>
                            <div className="content-wrapper">
                                <h3>
                                    Academic Life
                                </h3>
                                <p>
                                    I did my Bachelor's degree at the University of Montreal in pure mathematics, which I obtained in 2013. The two fields that interested me the most were Probability Theory and Algebraic Geometry, the latter being most interesting to me when studying symmetry of algebraic objects. Hesitating between the two topics for my master's degree, I ended up choosing Algebraic Geometry and moved to Berlin to pursue my studies. 
                                </p>
                                <p>
                                    Having won all possible scholarships wasn't enough, because my financial situation was hurt badly by my two herniated discs in my lower back, which were (and still are) very expensive to treat. Seeing that my financial situation wouldn't allow me to finish a PhD in time, and seeing that even if I did finish it, I wouldn't be living very well for a few years, I made the move to go to industry and refined my programming skills to become a data scientist / machine learner.
                                </p>
                                <p>
                                    On my free time, I still enjoy other topics (besides my two favorite above) such as graph theory, functional analysis, mathematical logic, differential geometry, algebraic and analytic number theory and representation theory. You can have a look at some texts I wrote on the subject <Link to="/projects/mathematics">here</Link>.
                                </p>
                            </div>
                        </ShadowBox>
                        <div style={{margin: "150px 150px 150px 100px", paddingLeft: "229px"}}></div> 
                        {/* GHOST IMAGE */}
                    </div>    
                </div>
        )
    }
}
