import React from "react";
import ShadowBox from "../../components/ShadowBox/ShadowBox"
import ChatRoom from "../../components/ChatRoom/ChatWidget"
import "./Chatbot.css";

export default () => (
    <>
    <ShadowBox>
        <div className="content-wrapper">
            <h3>Under construction!</h3>
            <p>
                You wish to have an initial conversation with me? This project will allow you to (a little bit)! With this chatbot, you will be able to ask about me, my background and my interests. Currently, this is working in progress. Stay posted for updates on the homepage!
            </p>
            <p>
                This project is not ready yet. An update will be on the home page when it is done!
            </p>
        </div>
    </ShadowBox>
    <ChatRoom/>
    </>
);