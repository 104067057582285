import React from "react";
import ShadowBox from "../../components/ShadowBox/ShadowBox"
import "./page404.css";

export default () => (
    <ShadowBox>
        <div className="text-wrapper">
            <h3>Sorry, this page doesn't exist!</h3>
        </div>
    </ShadowBox>
);
