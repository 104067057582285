import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLinkedin,
    faGithub,
    faStackExchange
  } from "@fortawesome/free-brands-svg-icons";
  import "./Icon.css"
  
export default function Icon() {
  const URLs = {
    linkedIn: 'https://www.linkedin.com/in/dasilvapatrick/',
    gitHub: 'https://github.com/Patrick-DS',
    stackExchange: 'https://math.stackexchange.com/users/10704/patrick-da-silva'
  } 
  
  return (
    <>
      <a
  href={URLs.linkedIn}
  className="linkedin icon"
  rel="noopener noreferrer"
  target="_blank"
>
  <FontAwesomeIcon icon={faLinkedin} size="2x" />
</a>

<a
  href={URLs.gitHub}
  className="github icon"
  target="_blank"
  rel="noopener noreferrer"
>
  <FontAwesomeIcon icon={faGithub} size="2x" />
</a>

<a
  href={URLs.stackExchange}
  className="stack-exchange icon"
  target="_blank"
  rel="noopener noreferrer"
>
  <FontAwesomeIcon icon={faStackExchange} size="2x" />
</a>
    </>
  );
}

// linkedin-in
// github
// stack-exchange