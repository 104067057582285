import React from "react";
import ReactPlayer from 'react-player'
import ShadowBox from "../../components/ShadowBox/ShadowBox"
import "./Mathematics.scss";

export default () => {
    const documentURLs = {
        AGALA: "https://dl.dropboxusercontent.com/s/zntae7zyakoxl6v/Algebraic-Groups-And-Lie-Algebras.pdf?dl=0",
        NCA: "https://dl.dropboxusercontent.com/s/wje3lnrw45u8maa/Non-commutative-Algebra.pdf?dl=0",
        RT: "https://dl.dropboxusercontent.com/s/nnu4a46869fyftg/Representation-Theory.pdf?dl=0",
        RTVideo: "http://player.vimeo.com/video/148783696",
        CA: "https://dl.dropboxusercontent.com/s/hcsecdm4dl8aoyt/Commutative-Algebra.pdf?dl=0"
    }
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <ShadowBox>
                <div className="headline-wrapper">
                    <h3>
                        Mathematics
                    </h3>
                    <p>
                        This section is here to display notes that I wrote to introduce students to a range of mathematical topics in abstract algebra. If you're interested, download away, and feel free to contact me with any questions!
                    </p>
                </div>
            </ShadowBox>
            <ShadowBox>
                <div className="content-wrapper">
                    <h3>
                        Representation theory of finite groups
                    </h3>
                    <p>
                        An introduction to the representation theory of finite groups with a focus on the field of complex numbers and the characteristic zero case (or more generally, when the prime field's characteristic does not divide the order of the group). The basic theory of representations, characters and G-modules are detailed in those notes.
                    </p>
                    <p>
                        <a href={documentURLs.RT}>Download here</a>
                    </p>
                    <p>
                        For those interested in what happens when the prime field's characteristic does divide the order of the group, I have given an introductory talk for the Berlin Mathematical School on representation theory and outlined the case where the prime field's characteristic divides the order of the group:
                    </p>
                    <div className="video-wrapper">
                        <div className="rounded-corners">
                            <ReactPlayer light url={documentURLs.RTVideo}/>
                        </div>
                    </div>
                </div>
            </ShadowBox>
            <ShadowBox>
                <div className="content-wrapper">
                    <h3>
                        Commutative Algebra
                    </h3>
                    <p>
                        A thorough introduction to the important topics within the field of commutative algebra that can be used as a solid foundation for diving deep into algebraic geometry. These were my personal notes that I built and reviewed over the years and I still plan to expand them to add more commutative algebraic topics.
                    </p>
                    <p>
                        <a href={documentURLs.CA}>Download here</a>
                    </p>
                </div>
            </ShadowBox>
            <ShadowBox>
                <div className="content-wrapper">
                    <h3>
                        Non-commutative algebra
                    </h3>
                    <p>
                        As I was interested in algebraic groups, I grew an interest for Lie algebras which are a particular case of non-commutative algebras. It turned out that non-commutative algebras on their own and I read Bourbaki in French to gain a profound understanding of their structure. These notes (written in English) are a result of my study, and serve as a reference for my notes on Lie algebras.
                    </p>
                    <p>
                        <a href={documentURLs.NCA}>Download here</a>
                    </p>
                </div>
            </ShadowBox>
            <ShadowBox>
                <div className="content-wrapper">
                    <h3>
                        Algebraic Groups and Lie Algebras
                    </h3>
                    <p>
                        A deep investigation of Lie Algebras with the purpose of understanding Algebraic Groups. Among many things, we prove the necessary results from linear algebra and we use some results from non-commutative algebra (proved in my other set of notes).
                    </p>
                    <p>
                        <a href={documentURLs.AGALA}>Download here</a>
                    </p>
                </div>
            </ShadowBox>
        </div>
    );
}